@tailwind base;
@tailwind components;
@tailwind utilities;

/* ==========================================================================
   Fonts
   ========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@font-face {
  font-family: 'Galano';
  src: url('./fonts/GalanoGrotesque-Light.eot');
  src: url('./fonts/GalanoGrotesque-Light.woff2') format('woff2'),
    url('./fonts/GalanoGrotesque-Light.woff') format('woff'),
    url('./fonts/GalanoGrotesque-Light.ttf') format('truetype'),
    url('./fonts/GalanoGrotesque-Light.svg#GalanoGrotesque-Light') format('svg'),
    url('./fonts/GalanoGrotesque-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Galano';
  src: url('./fonts/GalanoGrotesque-Regular.eot');
  src: url('./fonts/GalanoGrotesque-Regular.woff2') format('woff2'),
    url('./fonts/GalanoGrotesque-Regular.woff') format('woff'),
    url('./fonts/GalanoGrotesque-Regular.ttf') format('truetype'),
    url('./fonts/GalanoGrotesque-Regular.svg#GalanoGrotesque-Regular')
      format('svg'),
    url('./fonts/GalanoGrotesque-Regular.eot?#iefix')
      format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Galano';
  src: url('./fonts/GalanoGrotesque-Bold.eot');
  src: url('./fonts/GalanoGrotesque-Bold.woff2') format('woff2'),
    url('./fonts/GalanoGrotesque-Bold.woff') format('woff'),
    url('./fonts/GalanoGrotesque-Bold.ttf') format('truetype'),
    url('./fonts/GalanoGrotesque-Bold.svg#GalanoGrotesque-Bold') format('svg'),
    url('./fonts/GalanoGrotesque-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body,
html {
  font-family: 'Galano', sans-serif;
  color: #68666a;
  line-height: 1.8;
  background: #f9f5ef;
}

.great-vibes {
  font-family: 'Great Vibes', cursive;
  font-weight: 400;
  font-style: normal;
}

.ls {
  letter-spacing: 1.1rem;
}

.bg-primary {
  background: #7c725b;
}

.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.overlay {
  position: relative;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}

/** ANIMATIONS **/
.before_enter .anim {
  opacity: 0;
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
  will-change: transform;
}
.before_enter .fadeInUp {
  transform: translateY(15px);
}
.before_enter .fadeInDown {
  transform: translateY(-15px);
}
.before_enter .fadeInUpLarge {
  transform: translateY(30px);
}
.before_enter .fadeInDownLarge {
  transform: translateY(-30px);
}
.before_enter .fadeInLeft {
  transform: translateX(15px);
}
.before_enter .fadeInRight {
  transform: translateX(-15px);
}
.before_enter .zoomIn {
  transform: scale3d(0.95, 0.95, 1);
}
.before_enter .zoomOut {
  transform: scale3d(1.1, 1.1, 1);
}
.before_enter .scaleX {
  transform: scaleX(0);
}
.has_transition .anim {
  opacity: 1;
}
.has_transition .fadeInUp,
.has_transition .fadeInDown,
.has_transition .fadeInUpLarge,
.has_transition .fadeInDownLarge {
  transform: translateY(0);
}
.has_transition .fadeInLeft {
  transform: translateX(0);
}
.has_transition .fadeInRight {
  transform: translateX(0px);
}
.has_transition .zoomIn,
.has_transition .zoomOut {
  transform: scale3d(1, 1, 1);
}
.has_transition .scaleX {
  transform: scaleX(1);
}
.delay-100 {
  transition-delay: 100ms !important;
  transition-property: transform, opacity !important;
}
.delay-200 {
  transition-delay: 200ms !important;
  transition-property: transform, opacity !important;
}
.delay-300 {
  transition-delay: 300ms !important;
  transition-property: transform, opacity !important;
}
.delay-400 {
  transition-delay: 400ms !important;
  transition-property: transform, opacity !important;
}
.delay-500 {
  transition-delay: 500ms !important;
  transition-property: transform, opacity !important;
}
.delay-600 {
  transition-delay: 600ms !important;
  transition-property: transform, opacity !important;
}
.delay-700 {
  transition-delay: 700ms !important;
  transition-property: transform, opacity !important;
}
.delay-800 {
  transition-delay: 800ms !important;
  transition-property: transform, opacity !important;
}
.delay-900 {
  transition-delay: 900ms !important;
  transition-property: transform, opacity !important;
}
.delay-1000 {
  transition-delay: 1000ms !important;
  transition-property: transform, opacity !important;
}
.delay-1100 {
  transition-delay: 1100ms !important;
  transition-property: transform, opacity !important;
}
.delay-1200 {
  transition-delay: 1200ms !important;
  transition-property: transform, opacity !important;
}
.delay-1300 {
  transition-delay: 1300ms !important;
  transition-property: transform, opacity !important;
}
.delay-1400 {
  transition-delay: 1400ms !important;
  transition-property: transform, opacity !important;
}
.delay-1500 {
  transition-delay: 1500ms !important;
  transition-property: transform, opacity !important;
}
.delay-1600 {
  transition-delay: 1600ms !important;
  transition-property: transform, opacity !important;
}
.delay-1700 {
  transition-delay: 1700ms !important;
  transition-property: transform, opacity !important;
}
.delay-1800 {
  transition-delay: 1800ms !important;
  transition-property: transform, opacity !important;
}
.delay-1900 {
  transition-delay: 1900ms !important;
  transition-property: transform, opacity !important;
}
.delay-2000 {
  transition-delay: 2000ms !important;
  transition-property: transform, opacity !important;
}
.delay-2100 {
  transition-delay: 2100ms !important;
  transition-property: transform, opacity !important;
}
.delay-2200 {
  transition-delay: 2200ms !important;
  transition-property: transform, opacity !important;
}
.delay-2300 {
  transition-delay: 2300ms !important;
  transition-property: transform, opacity !important;
}
.delay-2400 {
  transition-delay: 2400ms !important;
  transition-property: transform, opacity !important;
}
.delay-2500 {
  transition-delay: 2500ms !important;
  transition-property: transform, opacity !important;
}
